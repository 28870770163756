<template lang="html">
    <div>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="title">
                    <v-img class="ma-2" src="/assets/logo.png" max-height="80" max-width="200" contain></v-img>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list>
            <div v-for="(item, i) in navi" :key="i">
                <v-list-group v-if="item.subpages">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.titel"></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item :to="makePath(item)">
                        <v-list-item-content>
                            <v-list-item-title v-text="item.titel" class="pl-5"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>


                    <div v-for="(sub, j) in item.subpages" :key="j">
                        <v-list-group sub-group v-if="sub.subpages">

                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title v-text="sub.titel"></v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item :to="makePath(sub)">
                                <v-list-item-content>
                                    <v-list-item-title v-text="sub.titel" class="pl-8"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-for="(ssub, k) in sub.subpages" :key="k" :to="makePath(ssub)">
                                <v-list-item-content>
                                    <v-list-item-title v-text="ssub.titel" class="pl-8"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>


                        </v-list-group>

                        <v-list-item :to="makePath(sub)" v-else>
                            <v-list-item-content>
                                <v-list-item-title v-text="sub.titel" class="pl-5"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </div>

                </v-list-group>
                <v-list-item v-else :to="makePath(item)">
                    <v-list-item-content>
                        <v-list-item-title v-text="item.titel"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-list>
    </div>
</template>

<script>
import {makePath} from '@/api/api'
export default {
    props: ['navi'],
    methods: {
        makePath
    },
}
</script>
